@layer base {
  .main {
  --local-icon-size: var(--sk-space-24);
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
}

.control {
  display: flex;
  gap: var(--sk-space-4);
}

.controlLabel {
  font-size: var(--sk-typography-body-1-regular-font-size);
  line-height: var(--sk-typography-body-1-regular-line-height);
}

.label {
  width: var(--local-icon-size);
  height: var(--local-icon-size);
  overflow: hidden;
  cursor: pointer;
}

.input {
  position: absolute;
  width: var(--local-icon-size);
  height: var(--local-icon-size);
  margin: 0;
  padding: 0;
  opacity: 0;
}

}