@layer base {
  .fieldWrapper {
  /* override component css */
  min-height: auto;
}

.booleanField + .booleanField {
  margin-left: var(--sk-space-24);
}

@media (--sk-large-viewport) {
  .booleanField + .booleanField {
    margin-left: var(--sk-space-56);
  }
}

}